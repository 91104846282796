<template>
  <div>
    <Header />

    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2">VISÃO SIMPLES</h2>-->

          <div class="rowBlock">
            <h1 class="TituloCor3">
              Tratamento<br />
              Antirreflexo
            </h1>

            <h1 class="TituloCor1 Hide" id="TituloVisaoSimples">
              Freeform Digital
            </h1>

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <div
              class="row Btns_ShowSemAntirreflexo DeixaInativo"
              id="Btns_ShowSemAntirreflexo"
            >
              <a
                href="javascript:void(0)"
                class="Txt_EmLinha Txt_EmLinha_Tamanho1 Show_SemAntirreflexo"
                id="ObsSem"
                >AO LADO, OS ÓCULOS ESTÃO</a
              >

              <a
                @click="Show_SemAntirreflexo()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2 BtnSemAntirreflexo BtnEmLinhaInativo Show_SemAntirreflexo"
                id="BtnSemAntirreflexo"
                >SEM ANTIRREFLEXO</a
              >
            </div>

            <div class="row Btns_ShowAntirreflexo" id="Btns_ShowAntirreflexo">
              <a
                href="javascript:void(0)"
                class="Txt_EmLinha Txt_EmLinha_Tamanho1 Show_Antirreflexo"
                id="ObsCom"
                >CLIQUE PARA APLICAR O</a
              >

              <a
                @click="Show_Antirreflexo()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2 BtnComAntirreflexo Show_Antirreflexo"
                id="BtnComAntirreflexo"
                >TRATAMENTO ANTIRREFLEXO</a
              >
            </div>

            <div class="row top20">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Tratamento Antirreflexo</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
<carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots= "true"
              :responsive="{0:{items:1,nav:false},450:{items:1,nav:true},1000:{items:1,nav:true}}"       
            >            

              <div class="item">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Antirreflexo_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Em ambientes com luz artificial ou com luz natural muito
                    intensa, a iluminação tende a refletir na superfície das
                    lentes formando um efeito de espelho e ocultando os olhos
                    dos usuários dos óculos.<br />
                    Além de atrapalhar a visão, causa um incômodo muito grande
                    numa conversa, pois o interlocutor não consegue olhar
                    diretamente nos olhos do usuário das lentes.
                  </p>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Antirreflexo_2.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Com o Tratamento Antirreflexo as lentes não sofrem este
                    efeito e se tornam totalmente transparentes, com um leve
                    residual azul ou verde (dependendo da marca escolhida).<br />
                    Revelam todas as expressões dos seus olhos, proporcionam
                    total liberdade de comunicação e garantem também maior
                    conforto visual e menor fadiga ocular.
                  </p>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Antirreflexo_3.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Após a produção das lentes, elas são submetidas a um moderno
                    processo no qual são aplicadas várias camadas compactas e
                    ultra finas, de apenas nanômetros de espessura, que conferem
                    características, benefícios e diferenciais que agregam muito
                    mais valor e qualidade às suas lentes.<br />
                    Os fabricantes das lentes oferecem várias categorias nas
                    quais algumas possuem todas estas características.
                  </p>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <img
                    src="./images/Antirreflexo/Banner_Antirreflexo_4.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Recomendável a todas as pessoas que usam óculos, pois
                    preocupa-se com questões ligadas a estética, proteção visual
                    e durabilidade. Ainda mais aconselhável para aqueles que se
                    encaixam nos casos acima.
                  </p>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <Buttons />

    <div
      class="DivFull_CenterCenter Antirreflexo_SemReflexos"
      id="SemReflexos"
    ></div>

    <div
      class="DivFull_CenterCenter Antirreflexo_ComReflexos"
      id="ComReflexos"
    ></div>
  </div>
</template>
<script>
import Header from '@/views/menu/components/header.vue'
import Buttons from '@/views/menu/components/anti_glare_buttons.vue'
import carousel from 'vue-owl-carousel'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

import {
  Show_SemAntirreflexo,
  Show_Antirreflexo,
} from '@/views/menu/js/new/AntiGlare_Script.js'

export default {
  methods: {
    Show_SemAntirreflexo,
    Show_Antirreflexo,
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,
  },
  components: {
    Header,
    Buttons,
    carousel
  },
}
</script>
<style scoped>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import './css/plugins/owl.carousel.min.css'; */
@import './css/plugins/owl.theme.default.min.css';

@import './css/Antirreflexo_Style.css';
</style>
