export function Show_SemAntirreflexo() {
  document.getElementById('SemReflexos').classList.remove('TiraOpacidade')
  document
    .getElementById('Btns_ShowAntirreflexo')
    .classList.remove('TiraOpacidade')
  document
    .getElementById('Btns_ShowSemAntirreflexo')
    .classList.add('TiraOpacidade')
  document
    .getElementById('BtnComAntirreflexo')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('BtnSemAntirreflexo')
    .classList.add('BtnEmLinhaInativo')
}

export function Show_Antirreflexo() {
  document.getElementById('SemReflexos').classList.add('TiraOpacidade')
  document
    .getElementById('Btns_ShowAntirreflexo')
    .classList.add('TiraOpacidade')
  document
    .getElementById('Btns_ShowSemAntirreflexo')
    .classList.remove('TiraOpacidade')
  document
    .getElementById('BtnComAntirreflexo')
    .classList.add('BtnEmLinhaInativo')
  document
    .getElementById('BtnSemAntirreflexo')
    .classList.remove('BtnEmLinhaInativo')
}
