<template>
  <div class="AntirreflexoEscolha">
    <a
      @click="OpenLens('anti-glare/1')"
      class="BtnAntirreflexo Antirreflexo_BtnActive"
      id="Chama_Reflexos"
    >
      <img
        src="../svg/Antirreflexo_Reflexos.svg"
        class="Icones"
        alt="Reflexos"
      />

      <h3>REFLEXOS</h3>
    </a>

    <a
      @click="OpenLens('anti-glare/2')"
      class="BtnAntirreflexo"
      id="Chama_Riscos"
    >
      <img src="../svg/Antirreflexo_Riscos.svg" class="Icones" alt="Riscos" />

      <h3>RISCOS</h3>
    </a>

    <a
      @click="OpenLens('anti-glare/3')"
      class="BtnAntirreflexo"
      id="Chama_Agua"
    >
      <img src="../svg/Antirreflexo_Agua.svg" class="Icones" alt="Agua" />

      <h3>ÁGUA</h3>
    </a>

    <a
      @click="OpenLens('anti-glare/4')"
      class="BtnAntirreflexo"
      id="Chama_Manchas"
    >
      <img src="../svg/Antirreflexo_Manchas.svg" class="Icones" alt="Manchas" />

      <h3>MANCHAS</h3>
    </a>

    <a
      @click="OpenLens('anti-glare/5')"
      class="BtnAntirreflexo"
      id="Chama_Sujeira"
    >
      <img src="../svg/Antirreflexo_Sujeira.svg" class="Icones" alt="Sujeira" />

      <h3>SUJEIRA</h3>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    OpenLens(route) {
      this.$router.push(`/lens/${route}`)
    },
  },
}
</script>

<style>
@import '../css/Style.css';
@import '../css/MyStyle.css';
@import '../css/MyLogin.css';
@import '../css/MyColors.css';
@import '../css/MyMenu.css';
@import '../css/MyMenuInterno.css';
@import '../css/plugins/animate.css';
@import '../css/plugins/hamburger.css';
/* @import '../css/plugins/owl.carousel.min.css'; */
@import '../css/plugins/owl.theme.default.min.css';

@import '../css/Antirreflexo_Style.css';
</style>
